var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseScrollActive',{staticClass:"base-navigation"},[_vm._l((this.$config.navigation),function(value,key){return [(key !== 'Trailer')?_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
        el: '#' + value.sitename,
        offset: -40,
      }),expression:"{\n        el: '#' + value.sitename,\n        offset: -40,\n      }"}],class:[
        'nav-item',
        'scrollactive-item',
        { 'is-active': key === _vm.activeItem },
      ],attrs:{"href":'#' + value.sitename}},[_vm._v(" "+_vm._s(key)+" ")]):_vm._e(),(key === 'Trailer')?_c('div',{staticClass:"nav-item open-trailer",class:{ 'is-active': key === _vm.activeItem },on:{"click":_vm.yt_video}},[_vm._v(" Trailer ")]):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }