<!-- @format -->

<template>
  <div
    class="scroll-container"
    :class="{ 'overflow-hidden': !animationComplete }"
  >
    <!--  <DesktopHeader />-->
    <transition name="fade">
      <div
        v-if="showLoadingSection"
        class="loading-section"
        :class="$mq"
        ref="loadingSection"
      >
        <div class="loading-section-top" :class="$mq">
          <div class="loading-hashtag" :class="$mq">
            <img src="@/assets/images/top/new/hashtag.png" alt="" />
          </div>
          <div class="loading-title" :class="$mq">
            <img src="@/assets/images/top/new/loading_title.png" alt="" />
          </div>
        </div>
        <div class="loading-kasten">
          <div class="loading-pfeil">
            <img src="@/assets/images/top/new/pfeil.png" alt="" />
          </div>
          <div class="weisser-kasten">
            <img src="@/assets/images/top/new/weisser_kasten.png" alt="" />
          </div>
        </div>
      </div>
    </transition>
    <div class="content-container">
      <DesktopTop :ratio="ratio" :trailer="trailer" :kinostart="kinostart" />

      <!--
    <Crew />
    <Musik />-->
      <Inhalt />

      <div class="dvd" id="dvd">
        <div class="wrapper container">
          <div class="left_side">
            <img
              class="packshot"
              src="@/assets/images/dvd/packshot.png"
              alt=""
            />
          </div>
          <div class="right_side">
            <div class="desktop-cites-top">
              <p class="desktop-cites-top-text" :class="$mq">
                „Gütiger Himmel,<br />
                was für ein Film!“
              </p>
              <p class="desktop-cites-top-author" :class="$mq">
                SÜDDEUTSCHE ZEITUNG
              </p>
            </div>
            <div class="txt">
              <p>
                <span class="extra">DVD</span>
              </p>
              <div class="amz-btn">
                <a
                  href="https://amzn.to/3QFH7ln"
                  class="vod_btn"
                  target="_blank"
                >
                  <img src="@/assets/images/dvd/amazon.jpg" alt="" />
                </a>
                <!--<span>*</span>-->
              </div>
            </div>
            <div class="video">
              <h2>Video on Demand</h2>
              <ul class="vod_btns">
                <li>
                  <a
                    href="https://tv.apple.com/de/movie/das-nonnenrennen/umc.cmc.3n0iewp0ytde798cv56ammsdz"
                    target="_blank"
                    ><img src="@/assets/images/dvd/itunes.jpg"
                  /></a>
                </li>
                <li>
                  <a href="https://amzn.to/483QYbw" target="_blank"
                    ><img src="@/assets/images/dvd/prime.jpg"
                  /></a>
                </li>
                <li>
                  <a
                    href="https://play.google.com/store/movies/details/Das_Nonnenrennen?id=oDDIdYiRjn0.P&gl=DE "
                    target="_blank"
                    ><img src="@/assets/images/dvd/google.jpg"
                  /></a>
                </li>
                <li>
                  <a
                    href="https://www.videoload.de/film/das-nonnenrennen/GN_MV020518280000"
                    target="_blank"
                    ><img src="@/assets/images/dvd/videoload.jpg"
                  /></a>
                </li>
                <li>
                  <a
                    href="https://store.sky.de/product/das-nonnenrennen/0a9d0aa2-48fa-4fdc-9d31-00b108df2d80"
                    target="_blank"
                    ><img src="@/assets/images/dvd/sky.jpg"
                  /></a>
                </li>
                <li>
                  <a href="https://www.kino-on-demand.com/movies/das-nonnenrennen" target="_blank"
                    ><img src="@/assets/images/dvd/ondemand.png"
                  /></a>
                </li>
              </ul>
              <p class="note">
                * Die mit Sternchen (**) gekennzeichneten Links sind sogenannte
                Affiliate-Links. Wenn Sie auf einen Affiliate-Link klicken und
                über<br />
                diesen Link einkaufen, erhält Prokino vom betreffenden
                Online-Shop oder Anbieter eine Provision. Für Sie verändert sich
                der Preis nicht.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="desktop-cites">
        <BaseCitesFade2 />
      </div>

      <!-- 
    <Fotos />-->
      <BaseFooter :billing="true" :kinostart="kinostart" />

      <BaseYoutube :youtube="$config.youtube" :margin="100" />
    </div>
  </div>
</template>

<script>
import Inhalt from '@/views/Inhalt.vue'
import BaseCitesFade2 from '@/components/Base/Cites/BaseCitesFade2.vue'
/* 
  import Fotos from '@/views/Fotos.vue'
import Hintergrundinfos from '@/views/Hintergrund.vue'
import Partner from '@/views/Partner.vue'
  import Regie from '@/views/Regie.vue'
  import Timeline from '@/views/Timeline.vue'
  import Statement from '@/views/Statement.vue'
  import Clip from '@/views/Clip.vue'
   */
import moment from 'moment'

export default {
  data() {
    return {
      showLoadingSection: true,
      fadeIn: false,
      animationComplete: false,
    }
  },
  components: {
    Inhalt,
    BaseCitesFade2,
    /*  
        Fotos,
    Hintergrundinfos,
    Partner,
      Regie, 
      Fotos,
      Timeline,
      Statement,
      Clip,
  */
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  computed: {
    kinostart() {
      return moment() < moment(this.$config.release_line_1.date)
        ? this.$config.release_line_1.line_before
        : this.$config.release_line_1.line_after
    },
    ratio() {
      return (
        this.$store.getters['Window/isSize'].width /
        this.$store.getters['Window/isSize'].height
      )
    },
    trailer() {
      return this.$config.youtube.id !== ''
    },
  },
  mounted() {
    this.$nextTick(() => {
      const loadingSection = this.$refs.loadingSection

      loadingSection.addEventListener('animationend', () => {
        loadingSection.classList.add('animate-complete')
        setTimeout(() => {
          this.showLoadingSection = false
          setTimeout(() => {
            this.animationComplete = true
          }, 2500)
        }, 2000) // 6000 milliseconds = 6 seconds
      })
    })
  },
  methods: {
    handleScroll() {
      const element = document.querySelector('.maedchen_fadein')
      if (element) {
        const rect = element.getBoundingClientRect()
        const windowHeight =
          window.innerHeight || document.documentElement.clientHeight
        this.fadeIn = rect.top <= windowHeight
      }
    },
    logout() {
      this.$store.dispatch('Auth/userSignOut')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.scroll-container {
  z-index: 0;
  position: relative;
  width: 100%;
  &.overflow-hidden {
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .loading-section {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999999;
    background-image: url('~@/assets/images/top/new/loading_image.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    /* Vollständige Bildschirmhöhe verwenden */
    display: flex;
    justify-content: center;
    align-items: center;
    animation: scaleAnimation 1.5s ease-in-out;

    &.xxxl,
    &.xxxxl {
      height: 100vh;
    }

    .loading-kasten {
      position: absolute;
      bottom: 0;
      margin: 0 auto;
      animation: opacityAnimation 2s ease;

      .loading-pfeil {
        position: absolute;
        top: 53%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        transition: transform 0.3s ease;
        animation: bounceAnimation 1s ease infinite;
      }

      @keyframes bounceAnimation {
        0%,
        100% {
          transform: translate(-50%, -50%);
        }

        50% {
          transform: translate(-50%, -30%);
        }
      }

      .weisser-kasten {
        position: relative;
        /* Relative Positionierung, um den absoluten Pfeil innerhalb des Kastens zu positionieren */
      }
    }

    .loading-section-top {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: -650px;

      &.lg {
        margin-top: -500px;
      }

      &.xxxl,
      &.xxxxl {
        margin-top: -850px;
      }

      .loading-title {
        animation: opacityAnimation 2s ease;
        width: 84%;

        img {
          width: 100%;
        }

        &.xxxl,
        &.xxxxl {
          width: 100%;
        }
      }

      .loading-hashtag {
        animation: opacityAnimation2 2s ease;
        width: 28%;
        margin-right: -250px;

        img {
          width: 100%;
        }

        &.xxxl,
        &.xxxxl {
          margin-right: -300px;
          width: 36%;
        }
      }
    }
  }

  @keyframes opacityAnimation {
    0% {
      opacity: 0;
    }

    20% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes opacityAnimation2 {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes scaleAnimation {
    0% {
      transform: scale(1.1);
      /* Größer */
    }

    100% {
      transform: scale(1);
      /* Normalgröße */
    }
  }

  /**  .loading-section.animate-complete .loading-title {
    opacity: 1;
  }
  .loading-section.animate-complete .loading-kasten {
    opacity: 1;
  } */
}

@media (max-height: 941px) {
  .scroll-container {
    .loading-section {
      .loading-section-top {
        .loading-hashtag {
          width: 22%;
        }

        .loading-title {
          width: 47%;
        }
      }
    }
  }
}

.content-container {
  height: 100vh;
}

.desktop-cites {
  width: 100%;
  margin-top: 40px;
  height: 100px;
}

.dvd {
  z-index: 99;
  $self: &;
  width: 1024px;
  height: 388px;
  font-family: 'Urbanist';
  padding: 0;
  padding-bottom: 0px;
  margin: 0 auto -40px auto;

  .container {
    padding: 0;
  }

  .wrapper {
    display: flex;
    justify-content: center;
    width: 100%;

    .left_side {
      margin-top: 25px;
      margin-right: -30px;
    }

    .right_side {
      margin-top: 25px;
      position: relative;
      .desktop-cites-top {
        width: 80%;
        position: absolute;
        right: -135px;
        top: -20px;
        .desktop-cites-top-text {
          font-family: 'Raleway';
          font-style: normal;
          color: #188fbc;
          font-size: 26px;
          margin: 0 auto;
          margin-bottom: 6px;
          text-align: center;
          width: 50% !important;
          font-weight: bold;
          line-height: 1.1;
          text-transform: uppercase;
          &.lg {
            width: 50% !important;
            font-size: 20px;
          }
          &.xl {
            font-size: 18px;
          }
          &.xxl {
            font-size: 22px;
          }
        }
        .desktop-cites-top-author {
          text-transform: uppercase;
          font-style: normal;
          font-weight: bold;
          text-align: center;
          font-size: 16px;
          color: #878787;
          font-family: 'Raleway';
          margin: 0 auto;

          line-height: 1.1;
          &.lg {
            font-size: 12px;
          }
          &.xl {
            font-size: 12px;
          }
          &.xxl {
            font-size: 14px;
          }
        }
      }

      .txt {
        margin-top: 10px;
        flex: 1;
        margin-left: 66px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .extra {
          font-weight: bold;
          color: #188fbc;
          font-family: 'Raleway';
          font-size: 30px;
        }

        p {
          margin-bottom: 0;
        }

        ul {
          margin-top: 10px;
          padding-left: 0 !important;
        }

        ul,
        li {
          //font-size: 6px;
          margin-left: 10px;
          margin-bottom: 0;
        }

        li span {
          font-size: 18px;
        }

        .amz-btn {
          display: flex;
          height: 46px;
          margin-left: 10px;
          margin-top: 10px;

          .disabled {
            pointer-events: none;
            opacity: 0.5;
          }

          span {
            display: block;
            margin-left: 3px;
            font-weight: 700;
            font-family: 'Oswald';
          }
        }

        .vod_btn {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 42px;
          margin-top: 5px;
          margin-bottom: 77px;

          .disabled {
            pointer-events: none;
            opacity: 0.5;
          }

          img {
            vertical-align: middle;
            max-height: 100%;
          }
        }
      }

      h2 {
        font-size: 30px;
        text-transform: uppercase;
        color: #188fbc;
        font-family: 'Raleway';
        margin-left: 66px;
        font-weight: bold;
        margin-bottom: 0 !important;
        margin-top: 30px !important;
      }

      .vod_btns {
        margin-left: 60px;
        position: relative;
        width: 560px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        /* vertical */
        justify-content: flex-start;
        /* horizontal */
        align-items: stretch;
        padding: 0 !important;
        margin-top: 0 !important;

        /* same height */
        .disabled {
          pointer-events: none;
          opacity: 0.5;
        }

        li {
          list-style: none;
          margin: 7px;
        }

        .vod_btn {
          flex: 0 1 50%;
          margin: 0 20px 14px 0;
          padding: 0 !important;

          .disabled {
            pointer-events: none;
            opacity: 0.5;
          }
        }
      }

      .note {
        font-size: 10px;
        line-height: 0.8rem;
        font-weight: 500;
        text-align: left;
        font-family: 'Roboto';
        margin-top: -10px;
        margin-left: 65px;
      }
    }
  }
}
</style>
