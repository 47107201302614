<!-- @format -->

<template>
  <flickity ref="flickity" :options="flickityOptions">
    <div
      v-for="cite2 in $config.cites2"
      :key="cite2.text"
      class="carousel-cell"
    >
      <cite class="base-cite2" :class="$mq">
        <p class="text2" :class="$mq" v-text="cite2.text" />
        <p class="author2" :class="$mq" v-text="cite2.author" />
      </cite>
    </div>
  </flickity>
</template>

<script>
  import Flickity from 'vue-flickity'
  require('flickity-fade')

  export default {
    components: {
      Flickity,
    },
    props: {
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
    },
    data() {
      return {
        flickityOptions: {
          initialIndex: 1,
          prevNextButtons: false,
          pageDots: false,
          autoPlay: true,
          fade: true,
          draggable: false,
        },
      }
    },
  }
</script>

<style lang="scss" scoped>
  .flickity-enabled,
  .flickity-viewport {
    height: 90.375px !important;
    width: 100%;
    text-align: center;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }
  .carousel-cell {
    width: 100% !important;
  }
  .base-cite2 {
    overflow-x: hidden;
    $self: &;
    .text2 {
      font-family: 'Raleway';
      font-style: normal;
      color: #188fbc;
      font-size: 26px;
      margin: 0 auto;
      margin-bottom: 6px;
      text-align: center;
      font-weight: bold;
      line-height: 1.1;
      width: 25%;
      text-transform: uppercase;
      &.md,
      &.sm {
        margin-top: 20px;
        margin-bottom: 6px;
        width: 100% !important;
        font-size: 18px;
      }
      &.lg {
        width: 40%;
        font-size: 20px;
      }
      &.xl {
        width: 30%;
        font-size: 20px;
      }
      &.xxxxl {
        width: 22%;
      }
      &.xxxl {
        width: 28%;
      }
      &.xxl {
        width: 25%;
        font-size: 22px;
      }
    }
    .author2 {
      text-transform: uppercase;
      font-style: normal;
      font-weight: bold;
      text-align: center;
      font-size: 16px;
      color: #878787;
      font-family: 'Raleway';
      margin: 0 auto;
      widows: 100% !important;
      line-height: 1.1;
      &.md,
      &.sm {
        width: 100% !important;
        font-size: 12px;
      }
      &.lg {
        width: 50%;
        font-size: 12px;
      }
      &.xl {
        font-size: 12px;
        widows: 100% !important;
      }
      &.xxl {
        widows: 100% !important;
        font-size: 14px;
      }
    }
  }
</style>
