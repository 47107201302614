<!-- @format -->
<template>
  <mq-layout :mq="['sm', 'md']">
    <div class="mob-index mob-index-top mob-header" id="mob-index mob-header">
      <!--<BaseKinostart :kinostart="kinostart" class="kinostart-mob" />-->

      <img src="@/assets/images/bg_top_mobile.jpg" alt="" />
    </div>
  </mq-layout>
</template>

<script>
  import { EventBus } from '@/plugins/event-bus.js'

  export default {
    props: {
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
      showKinofinder: {
        type: Boolean,
        default: true,
      },
    },
  }
</script>

<style lang="scss">
  .no-scroll {
    overflow: hidden;
    height: 100vh;
    scrollbar-width: 0;
    z-index: 0;
  }
  .fullScreen {
    height: 100vh;
    z-index: 0;
  }
  .mob-index-top {
    //margin-top: 50px;
    width: 100%;
    margin-bottom: -1px;
    img {
      width: 100%;
    }
  }
  .mob-index {
    position: relative;
    z-index: -20;
    $self: &;
    img {
      width: 100%;
    }
  }

  .kinostart-mob {
    background-color: white;
    color: black;
    font-family: 'Roboto Slab';
    width: 100%;
    $min_width: 300px;
    $max_width: 1024px;
    $min_font: 12px;
    $max_font: 55px;
    @include fluid-type($min_width, $max_width, $min_font, $max_font);
    line-height: 1.1;
    text-align: center;
    text-transform: uppercase;
    padding: 0px 0px;
    z-index: 1;
    font-weight: 400;
    &.sm {
      padding: 0px 0px;
    }
  }

  .bottom {
    &.sm,
    &.md {
      height: auto;
      left: 0;
      position: relative;
      width: 100%;
      z-index: 10;
    }
  }
</style>
