<template>
  <div class="inhalt-overview" :class="$mq" id="inhalt">
    <div class="inhalt desktop-inhalt" :class="$mq" id="desktop-inhalt">
      <div class="desktop-inhalt-content">
        <div class="inhalt-movie">
          <img src="@/assets/images/inhalt/movie.png" alt="" :class="$mq" />
        </div>
        <div class="inhalt-text">
          <div class="inhalt-text-content">
            <div class="text-left" :class="$mq">
              <p>
                In einem kleinen Benediktiner-Kloster in der tiefsten Provinz
                Frankreichs haben es sich die Nonnen zur heiligen Mission
                gemacht, ein marodes Altersheim zu sanieren. Doch die einzig
                ersichtliche Geldquelle ist das Preisgeld des örtlichen
                Radrennens. Das Problem: keine der frommen Damen sitzt fest im
                Sattel. Aber sie wissen sich zu helfen – und das nicht nur mit
                christlichen Mitteln. Die Rennstrecke scheint frei für ihren
                Sieg, bis ein neues Team auftaucht: angeführt von einer
                Ordensschwester, die seit Schulzeiten die Erz-Feindin unserer
                nicht immer ehrwürdigen Mutter Oberin ist.
              </p>
              <p>
                <span>
                  Die guten Schwestern treten beseelt in die Pedale und geben
                  ordentlich Gas! Wer wird als schnellste Nonne über die
                  Ziellinie fahren und den Sieg davontragen? Ein Rennen, das man
                  nicht verpassen sollte!</span
                >
              </p>
            </div>
          </div>
        </div>
        <!--     <div class="inhalt-fotos" :class="$mq">
           <img src="@/assets/images/inhalt/fotos.png" alt="" :class="$mq" />
          <div class="inhalt-fotos-content">
            <div class="bild_02">
              <img
                src="@/assets/images/inhalt/bild_02.png"
                alt=""
                :class="$mq"
              />
            </div>
            <div class="bild_01">
              <img
                src="@/assets/images/inhalt/bild_01.png"
                alt=""
                :class="$mq"
              />
            </div>
            <div class="bild_03">
              <img
                src="@/assets/images/inhalt/bild_03.png"
                alt=""
                :class="$mq"
              />
            </div>
          </div>
        </div>
        <div class="inhalt-zitate">
          <div class="zitat-content zitat-1">
            <div class="zitat-content-text">
              „Penélope Cruz hätte alle Preise verdient”
            </div>
            <div class="zitat-content-author">LE FIGARO</div>
          </div>
          <div class="zitat-content zitat-2">
            <div class="zitat-content-text">„So wahr wie unsere Träume”</div>
            <div class="zitat-content-author">
              ARD Titel Thesen Temperamente
            </div>
          </div>
          <div class="zitat-content zitat-3">
            <div class="zitat-content-text">„Unermesslich berührend“</div>
            <div class="zitat-content-author">Le Parisien</div>
          </div>
          <div class="zitat-content zitat-4">
            <div class="zitat-content-text">„Betörend“</div>
            <div class="zitat-content-author">Screen Daily</div>
          </div>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
  import { EventBus } from '@/plugins/event-bus.js'
  export default {
    props: {
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
      ratio: {
        type: Number,
        default: 1.78,
      },
      trailer: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      isFixed() {
        return this.position === 'fixed'
      },
    },

    methods: {
      ytVideo() {
        EventBus.$emit('open-yt')
      },
      kinoliste() {
        EventBus.$emit('open-kinoliste')
      },
    },
  }
</script>

<style lang="scss">
  .inhalt-overview {
    display: flex;
    flex-direction: column;
    background-image: url('~@/assets/images/inhalt/bg.png');
    background-size: cover;
    background-position: center;
    height: 1887px;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    &.lg {
      height: 1915px;
      background-image: url('~@/assets/images/inhalt/bg_small.png');
    }
    &.xl {
      height: 1887px;
    }
    &.xxl,
    &.xxxl,
    &.xxxxl {
      background-image: url('~@/assets/images/inhalt/bg_large.png');
    }
    .inhalt {
      z-index: 99;
      $self: &;
      width: 100%;
      font-family: 'Raleway';
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .desktop-inhalt-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 1308px;
        .inhalt-movie {
          margin-bottom: 49px;
        }
        .inhalt-text {
          .inhalt-text-content {
            display: flex;
            span {
              font-weight: bold;
            }
            .text-left {
              display: flex;
              justify-content: center;
              flex-direction: column;
              align-items: center;
              margin-top: -15px;
            }
            .text-left p {
              line-height: 1.5;
              font-size: 17px;
              font-weight: 400;
              color: white;
              width: 68%;
              margin-bottom: 0;
            }
          }
        }
        .inhalt-fotos {
          margin-top: 33px;
          margin-bottom: 36px;
          height: auto;
          width: 100%;
          .inhalt-fotos-content {
            position: relative; // Stellt sicher, dass die Positionierung der Bilder relativ zu diesem Container ist
            height: 500px;
            .bild_01 {
              position: absolute; // Absolute Positionierung, um die Bilder übereinander zu stapeln
              top: 190px; // Anpassen der Position der einzelnen Bilder (kann je nach Bedarf variieren)
              left: 50%;
              transform: translateX(-50%);
              z-index: 88888;
            }
            .bild_02 {
              position: absolute; // Absolute Positionierung, um die Bilder übereinander zu stapeln
              top: -5px; // Anpassen der Position der einzelnen Bilder (kann je nach Bedarf variieren)
              left: 85px;
            }
            .bild_03 {
              position: absolute; // Absolute Positionierung, um die Bilder übereinander zu stapeln
              top: -20px; // Anpassen der Position der einzelnen Bilder (kann je nach Bedarf variieren)
              right: 50px;
            }
          }
        }
        .inhalt-zitate {
          .zitat-1 {
            margin-left: -480px;
          }
          .zitat-2 {
            margin-right: -530px;
            margin-top: -20px;
          }
          .zitat-3 {
            margin-left: -740px;
          }
          .zitat-4 {
            margin-right: -480px;
          }
          .zitat-content {
            text-align: center;
            .zitat-content-text {
              font-family: 'Roboto Slab';
              font-size: 25px;
              color: white;
            }
            .zitat-content-author {
              margin-bottom: 15px;
              margin-top: 5px;
              font-size: 15px;
              font-family: 'Raleway';
              font-weight: 500;
            }
          }
        }
      }
    }
    .rote-linie {
      position: absolute;
      bottom: -6px;
      width: 100%;
      img {
        width: 100%;
      }
      .rote_small {
        &.lg,
        &.xl,
        &.xxl {
          display: block;
        }
        &.xxxl,
        &.xxxxl {
          display: none;
        }
      }
      .rote_long {
        &.lg,
        &.xl,
        &.xxl {
          display: none;
        }
        &.xxxl,
        &.xxxxl {
          display: block;
        }
      }
    }
  }
</style>
